<template>
  <div class="hello">
    <div class="top">
      <img :src="app_icon" />
      <div class="title">{{ name }}</div>
    </div>
    <div class="bottom">
      <div>版本号:v{{ version_name }}</div>
      <div>请使用浏览器打卡</div>
      <div>{{ api }}</div>
      <div class="button_list">
        <img @click="ToDown()" src="@/assets/images/android_btn.png" />
        <img @click="ToLogin()" src="@/assets/images/web_btn.png" />
      </div>
    </div>
  </div>
</template>

<script>
import Tools from "@/ts/Tools";
import Config from "@/ts/Config";
export default {
  name: "HelloWorld",
  props: {
    msg: Number,
  },
  data() {
    return {
      app_icon: "",
      guid: "",
      version_name: "",
      api: "",
      name: "",
    };
  },
  methods: {
    /**
     * 是否为微信浏览器
     */
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    ToDown() {
      let apk_url = `${Config.APK_DOWN_HOST}/app/${this.guid}/i.apk`;
      if (this.isWeiXin()) {
        alert("请使用本地浏览器下载!");
      } else {
        window.location.href = apk_url;
      }
    },
    ToLogin() {
      window.location.href = this.api;
    },
    async GetDownPageDataFromDownHOSTAsync() {
      let down_host = window.location.hostname;
      let res = await Tools.GetHtmlAsync("GetDownPageDataFromDownHOST", {
        down_host: down_host,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;
        /**
          pf_vo.app_applicationId,
          pf_vo.app_down_host,
          pf_vo.app_icon,
          pf_vo.app_version_code,
          pf_vo.app_version_name,
          pf_vo.can_down_app
         */
        this.guid = d.guid;
        this.api = d.host;
        this.version_name = d.app_version_name;
        this.name = d.platform_name;
        this.app_icon = d.app_icon;
      }
    },
  },
  async mounted() {
    await this.GetDownPageDataFromDownHOSTAsync();
  },
};
</script>
<style scoped>
.hello {
  background-color: #070d0d;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
  /* background-image: url("@/assets/images/bg.png"); */
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.top {
}
.top > img {
  width: 100px;
  height: 100px;
}
.title {
  color: rgb(237, 187, 124);
  font-size: 18px;
}
.bottom > div {
  margin-bottom: 10px;
}
.button_list {
  display: flex;
  justify-content: center;
}
.button_list > img {
  color: rgb(57, 47, 39);
  font-size: 16px;
  margin: 10px;
}
</style>
