import Config from "./Config";

export default class Tools {

    /**
     * 检测数据是否正确
     * @param res 
     * @param show 是否显示 默认显示错误提示
     */
    static CheckHtmlIsOK(res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }, show_error_msg: boolean = true) {
        switch (res.state) {
            case 1:
                return true;
            default:
                if (show_error_msg) {
                    Tools.AlertError(res.error_msg);
                }
                else {
                    console.log(`错误:${res.error_msg}`);
                }
                return false;
        }
    }


    /**
     * 异步请求
     * @param action 
     * @param data 
     * @param show_jiazaizhong 
     */
    static async GetHtmlAsync(action: string, data: any, files: File[] = []): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {

        return new Promise((successfun, errorfun) => {
            this.GetHtml(action, data, (res) => {
                // Tools.ShowLog(`HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                successfun(res);
            }, err => {
                // Tools.ShowLog(`HTML请求出错【${action}】`, "HTML请求出错", "#8B0000");
                errorfun(err);
            }, files);
        });
    }

    /**
     * 获取HTML
     * @param action 
     * @param data 
     * @param success 
     * @param fail 
     */
    static GetHtml(action: string, data: any, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }) => void, fail?: (err: any) => void, files: File[] = []) {
        try {
            if (data == null)
                data = {};

            let url = `${Config.WEB_API_HOST}?a=${action}`;
            var xhr = new XMLHttpRequest();
            var formdata: FormData | string;
            if (files.length > 0) {
                formdata = new FormData();
                for (let key in data) {
                    if (data[key] != null && data[key] != undefined)
                        url += "&" + key + "=" + encodeURIComponent(data[key]);
                }
                if (files) {
                    if (files.length > 0) {
                        for (let i = 0; i < files.length; i++) {
                            const f = files[i];
                            formdata.append('fileUpload', f);
                        }
                    }
                }
            }
            else {
                formdata = "";
                for (let key in data) {
                    if (data[key] != null && data[key] != undefined)
                        formdata += key + "=" + encodeURIComponent(data[key]) + "&";
                }
                if (formdata != "") {
                    formdata = formdata.substr(0, formdata.length - 1);
                }
            }
            xhr.open("POST", url, true);
            if (files.length > 0) {
                // xhr.setRequestHeader("Content-type", "multipart/form-data");
            }
            else {
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            }


            xhr.onload = function (e) {
                try {
                    if (this.status == 200 || this.status == 304) {
                        if (success != null)
                            success(JSON.parse(this.responseText));
                    }
                    else {
                        Tools.AlertError("网络请求失败 错误:" + this.status);
                    }
                }
                catch (ex) {
                    console.error(ex);
                }
            };
            xhr.send(formdata);

        }
        catch (err) {
            console.error(err);
            console.log("加载HTML错误:" + err);
        }
    }

    public static AlertError(error_msg: string) {
        alert(error_msg);
    }

    public static AlertSuccess(msg: string) {
        alert(msg);
    }
}